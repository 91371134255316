<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header justify-content-start">
        <button class="btn btn-control" @click="$emit('close')">
          <img alt="Return back" src="@/assets/icons/bold-icon previous.svg" />
        </button>
        <h3 class="ms-2">{{ modalTitle }}</h3>
      </div>
      <div class="modal-body">
        <page-spinner v-if="ui.loading" />
        <div v-else>
          <div class="modal-body-container">
            <div class="form-group">
              <label>State</label>
              <multiselect
                v-model="state.state"
                :allow-empty="false"
                :close-on-select="true"
                :options="statesModal"
                :searchable="true"
                :show-labels="false"
                class="w-50"
                placeholder="State">
              </multiselect>
            </div>
            <div class="d-flex w-100 mt-3">
              <div class="form-check form-switch">
                <input v-model="state.isW2" class="form-check-input" type="checkbox" id="w2Switcher"/>
                <label class="form-check-label" for="w2Switcher">W2</label>
              </div>
              <div class="form-check form-switch ms-3">
                <input v-model="state.is1099" class="form-check-input" type="checkbox" id="taxType"/>
                <label class="form-check-label" for="taxType">1099</label>
              </div>
            </div>
            <div class="licenses-item">
              <div class="form-group">
                <label>License #</label>
                <input type="text" class="form-control" v-model="state.license"/>
              </div>
              <div class="form-group">
                <label>License Type (Description)</label>
                <textarea name="" class="form-control" v-model="state.licenseType" cols="30" rows="1"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <base-button :title="saveButtonText" action="secondary-default" @click-btn="save" :loading="ui.saving" />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import BaseButton from '../../../components/BaseButton.vue';

export default {
  name: "StateActionModal",
  components: {
    Multiselect,
    PageSpinner: () => import("../../../components/pageSpinner.vue"),
    BaseButton,
  },
  props: {
    stateId: {
      default: null,
      required: false,
    },
    states: {
      required: true,
    },
  },
  data() {
    return {
      state: {},
      ui: {
        loading: false,
        saving: false
      },
      statesModal: []
    };
  },
  computed: {
    modalTitle() {
      return this.stateId !== null ? "Edit State" : "Add State";
    },
    saveButtonText() {
      return this.stateId !== null ? "Edit State" : "Save State";
    },
    isEdit() {
      return this.stateId !== null;
    },
  },
  beforeMount() {
    if (this.isEdit) {
      this.getState();
    }
    this.statesModal = this.states
  },
  methods: {
    getState() {
      this.ui.loading = true;
      this.$http.get(`/api/v1/state-licenses/${this.stateId}/show`)
        .then((response) => {
          this.state = response.data.state
          this.ui.loading = false;
        })
        .catch((e) => {
          console.log(e)
        })
    },
    save() {
      this.ui.saving = true;
      let url = this.stateId === null
        ? '/api/v1/state-licenses/add'
        : `/api/v1/state-licenses/${this.stateId}/update`

      let formData = new FormData
      for ( let key in this.state ) {
        formData.append(key, this.state[key]);
      }

      this.$http.post(url, formData)
        .then(() => {
          this.ui.saving = false;
          this.$emit('close', true)
        })
        .catch((e) => {
          this.ui.saving = false;
          console.log(e)
        })
    },
  },
};
</script>
<style lang="scss" scoped>
.form-check {
  padding-left: 0;
  &-input {
    margin: 0;
    padding: 0;
    min-width: 35px;
  }
}
.modal-body-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.licenses {
  &-item {
    display: flex;
    width: 100%;
    padding: 17px 0;
    .form-group {
      input {
        height: 40px;
      }
      textarea {
        min-height: 40px;
      }
      &:first-child {
        width: 30%;
        max-width: 30%;
      }
      &:nth-child(2) {
        width: 60%;
        max-width: 60%;
        margin-left: 12px;
        margin-right: 8px;
      }
    }
    .btn-control {
      width: 40px;
      height: 40px;
      max-height: 40px;
      min-height: 40px;
      margin-top: 30.5px;
      img {
        width: 26px;
        height: 26px;
      }
    }
  }
  &-add {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 20px;

    span {
      display: block;
      margin-left: 4px;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }
}
.modal-footer {
  .btn-outline-dark {
    border: 1px solid #000000;
    border-radius: 8px;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
  }
  .bg-green {
    min-width: 105px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
  }
}
</style>
